<template>
  <v-card-text>
    <Editor :editMode="editMode" :itemsData="itemsData" :initial="dataItem" @data="save" />
  </v-card-text>
  <!-- <v-card-text>
          <router-view />
  </v-card-text>-->
</template>
<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor
  },
  data() {
    return {
      dataItem: {},
      itemsData: {},
      editMode: true
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getSurveys",
      immediate: true
    }
  },
  methods: {
    getSurveys(id) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/surveys/${id}`)
        .then(res => {
          self.itemsData = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      //logic comes here
      console.log(this.$route, "route");
      const url = "/surveys/" + this.$route.query.id;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then(res => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/survey");
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    }
  }
};
</script>
